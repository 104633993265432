import * as firebase from 'firebase/app';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyBCTEPb4UHtKwqCVho3A9w3R5BcxfIYs-c",
    authDomain: "myboilerplate-ed0fc.firebaseapp.com",
    databaseURL: "https://myboilerplate-ed0fc.firebaseio.com",
    projectId: "myboilerplate-ed0fc",
    storageBucket: "myboilerplate-ed0fc.appspot.com",
    messagingSenderId: "711622257348",
    appId: "1:711622257348:web:c4021e140b6581aa80f791"
  };


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase.auth();