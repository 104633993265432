import React from 'react';

const NavBar = () => {
	return (
		<div className="w3-bar w3-teal w3-xlarge">
			<span className="w3-bar-item">TableTopics.app</span>
		</div>
	)
};

export default NavBar;