import React from 'react';
import ShareButton from './ShareButton';
import { getLang } from '../utils';

function TableTopicInfo({loadingTT, errorTT, dataTT}){



	const lang = getLang();

	const m = {
		sessionId :{
			"en": "Session Id",
			"es": "Id"
		},
		topicsmaster: {
			"en": "Topicsmaster",
			"es": "Director de temas"
		}
	};



	if(loadingTT){
		return <div>Loading...</div>
	}

	if(errorTT){
		return <div>Error...<br/>${errorTT}</div>
	}

	
	if(dataTT.table_topics[0]){
		// console.log('dataTT', dataTT);

		return(
			<div className="w3-container">
				<p style={{display: 'inline-block'}}><i className="fas fa-calendar-alt w3-margin-right w3-large w3-text-teal"></i>{m.sessionId[lang]}: {dataTT.table_topics[0].id1} - {dataTT.table_topics[0].id2} - {dataTT.table_topics[0].id3} 
				</p>
				<ShareButton dataTT={dataTT}/>
					{/* <button className="w3-teal w3-button w3-margin-left w3-round-large" onClick={shareId}>Copy <i className="far fa-copy"></i></button> */}
					{/* <br/>
					({new Date(dataTT.table_topics[0].tt_date).toDateString()  })   */}
				
				<p><i className="fas fa-user-tie w3-margin-right w3-large w3-text-teal"></i>{m.topicsmaster[lang]}: {dataTT.table_topics[0].director} </p>
				<p><i className="fas fa-comment-dots w3-margin-right w3-large w3-text-teal"></i>{dataTT.table_topics[0].short_desc}</p>

			</div>
		);
	}
	return null;
}

export default TableTopicInfo;