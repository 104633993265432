import React from 'react';
import { Link } from 'react-router-dom';
import { getLang } from '../utils';

const NavBar = () => {

	const lang = getLang();

	const m = {
		create: {
			"en": "Create New Session",
			"es": "Crear Nueva Sesión"
		}
	}

	return (
		<div className="w3-bar w3-teal w3-xlarge">
			<Link to="/" style={{textDecoration: 'none'}} className="w3-bar-item">&times;</Link>
			<span className="w3-bar-item">{m.create[lang]}</span>
		</div>
	)
};

export default NavBar;