import React, { useState } from 'react';
import { useSubscription, useQuery, useMutation } from '@apollo/react-hooks';

import Evaluator from './Evaluator';
import { getMax } from '../utils';
import YourPlacesDisplay from '../common/YourPlacesDisplay';
import { getLang } from '../utils';

import { NOTIFY_NEW_EVALUATOR, UPDATE_TABLE_TOPIC } from '../../graphql/Dashboard';
import { GET_SPEAKERS } from '../../graphql/Speakers';


const EvaluatorsList = ({sessionId, allEvaluators}) => {

	const lang = getLang();

	const m = {
		evaluators: {
			"en": "Evaluators:",
			"es": "Evaluadores:"
		},
		calcWinners: {
			"en": "Calculate Winners",
			"es": "Calcular Ganadores"
		},
		winnersAre:{
			"en": "The winners for this Table Topics session are:",
			"es": "Los ganadores de este concurso/sesión son:"
		},
		thxUsing:{
			"en": "Thank you for using ",
			"es": "Gracias por usar "
		},
	}

	const [winners, setWinners] = useState("");
	const [updateTableTopic] = useMutation(UPDATE_TABLE_TOPIC);

	const { loading: loadingSPK, error: errorSPK, data: dataSPK } = useQuery(
		GET_SPEAKERS, {variables: {"table_topic_id": sessionId} });

	const calcWinners = (allSpeakers, allEvaluators) => {

		let totals = allSpeakers.reduce((acc,speaker) => ({...acc,  [speaker.id]: 0 })  , {} );

		allEvaluators.map(evaluator => {

			let evalWinners = JSON.parse(evaluator.winners);
			evalWinners.first.map(winner => totals = {...totals, [winner]: totals[winner] + 3 });
			evalWinners.second.map(winner => totals = {...totals, [winner]: totals[winner] + 2 });
			evalWinners.third.map(winner => totals = {...totals, [winner]: totals[winner] + 1 });
			return null;
		});

		// console.log('totals', totals);

		const first = getMax(totals);
		// console.log('first', first);
		first.map(winner => delete totals[winner]);

		const second =  getMax(totals);
		// console.log('second', second);
		second.map(winner => delete totals[winner]);

		const third = getMax(totals);
		// console.log('third', third);
		third.map(winner => delete totals[winner]);


		const localWinners = {"first": first, "second": second, "third": third};
		setWinners(localWinners);

		updateTableTopic({
			variables: {
				"table_topic_id": sessionId,
				"status": "closed",
				"winners": JSON.stringify(localWinners)
			}
		})

	}



	if (loadingSPK) {
		return <span>Loading Speakers...</span>;
	}
	if (errorSPK) {
		return <span>Error Loading Speakers</span>;
	}

	if(dataSPK){
		const allSpeakers = dataSPK.tt_speaker;


		// console.log('allSpeakers', allSpeakers);
		// console.log('allEvaluators', allEvaluators);

		if(allEvaluators.length){

			return(
				<>
				<p>{m.evaluators[lang]}</p>
				<div>
				{ allEvaluators.map(evaluator => <Evaluator key={evaluator.id} evaluator={evaluator} allSpeakers={allSpeakers} /> ) }
				</div>

				{ !winners 
					?
						<div style={{display: 'flex', justifyContent: 'center', padding: '12px 12px 40px 12px'}}>
								<button className="w3-button w3-teal"  onClick={()=>calcWinners(allSpeakers, allEvaluators)}>{m.calcWinners[lang]}</button>
						</div>
					:
					<>
						<br/>
						<YourPlacesDisplay 
							title={m.winnersAre[lang]}
							winners={winners}
							allSpeakers={allSpeakers}
							bgColor="w3-teal"
							message3={m.thxUsing[lang]}
						/>
					</>
				}
				</>
			);
		} else {
			return(
				<p>No ballots received yet.</p>
			)
		}




	}

}


const EvaluatorsListSubscription = ({sessionId}) => {

	const { loading, error, data } = useSubscription(NOTIFY_NEW_EVALUATOR, {variables: {"table_topic_id": sessionId}});
	if (loading) {
	  return <span>Fetching evaluators...</span>;
	}
	if (error) {
	  return <span>Subscription Error</span>;
	}

	return (
	  <EvaluatorsList 
		  sessionId={sessionId}	 
		  allEvaluators={data.tt_evaluator}
	  />
	);
  };

export default EvaluatorsListSubscription;