import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
	return (
		<div className="w3-bar w3-indigo w3-xlarge">
			{/* <Link to="/" style={{textDecoration: 'none'}} className="w3-bar-item">&times;</Link> */}
			<span className="w3-bar-item">TableTopics Dashboard</span>
		</div>
	)
};

export default NavBar;