import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { getLang } from '../utils';

import { ENABLE_VOTES_SUBMISSION } from '../../graphql/TableTopics';

const EnableSubmissionPanel = ({sessionStatus, sessionId}) => {

	const lang = getLang();

	const m= {
		text1: {
			"en": "After the last speaker has finished, enable votes submission.",
			"es": "Después del último orador, habilita el envío de votos."
		},
		enableBtn: {
			"en": "Enable Votes Submission",
			"es": "Habilitar Envío de Votos"
		}
	}

	const [enableVotesSubmission] = useMutation(ENABLE_VOTES_SUBMISSION);


	const clickToEnable =  () => {

		enableVotesSubmission({
			variables: {
				"table_topic_id": sessionId
			}
		});
	}


	return(
		<div style={{display: sessionStatus === "open" ? "block" : "none", marginBottom: "28px"}}
			className="w3-container w3-amber w3-padding">
			<p>{m.text1[lang]}</p>
			<div style={{display: 'flex', justifyContent: 'center'}}>
	<button onClick={clickToEnable} style={{backgroundColor:"#806104", color: "#fff"}} className="w3-button">{m.enableBtn[lang]}</button>
			</div>
		</div>
	);
};

export default EnableSubmissionPanel;