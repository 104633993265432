import React from 'react';

import { getLang } from '../utils';

const PurposeMsg = () => {
	
	const lang = getLang();

	if(lang === "es"){
		return(
			<div>
				<div className="w3-container w3-padding w3-center">
					<p 
						style={{"marginBottom": '0px', "textShadow": "1px 1px 0 #444"}}
						className="w3-wide">
							SALVA ÁRBOLES!</p>
					<p style={{"marginTop": '0px'}}>Usa esta app en vez de la boleta de papel</p>
				</div>

				<div className="w3-container w3-padding">
					<p>Con esta app puedes:</p>
					<ul>
						<li>Hacer concursos en línea y presenciales.</li>
						<li>Agregar oradores con antelación o cuando sean anunciados.</li>
						<li>Calcular los votos de cada juez/evaluador automáticamente.</li>
						<li>Enviar los votos y obtener los ganadores con un botón.</li>
					</ul>
				</div>

				<div className="w3-container w3-small">
					<p>Puede ser muy útil para los Table Topics de Toastmasters.
						Si nunca has escuchado de ellos te sugerimos los
						visites en <a target="_blank" rel="noopener noreferrer" href="https://www.toastmasters.org">www.toastmasters.org</a>.
					</p>
				</div>
			</div>
		);

	} else {

		return(
			<div>
				<div className="w3-container w3-padding w3-center">
					<p 
						style={{"marginBottom": '0px', "textShadow": "1px 1px 0 #444"}}
						className="w3-wide">
							SAVE TREES!</p>
					<p style={{"marginTop": '0px'}}>Use this app instead of the paper ballot</p>
				</div>

				<div className="w3-container w3-padding">
					<p>With this app you can:</p>
					<ul>
						<li>Conduct speech contests online and on-site.</li>
						<li>Add the speakers names beforehand, or when they are announced.</li>
						<li>Calculate each judge/evaluator votes automatically.</li>
						<li>Submit votes and calculate winners with a button.</li>
					</ul>
				</div>

				<div className="w3-container w3-small">
					<p>It can be particularly useful for Toastmasters' Table Topics.
						If you've never heard of them, we strongly recommend you check
						them out at <a target="_blank" rel="noopener noreferrer" href="https://www.toastmasters.org">www.toastmasters.org</a>.
					</p>
				</div>
			</div>
		);
	}
};

export default PurposeMsg;