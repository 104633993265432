import React from 'react'
import { withRouter } from 'react-router'

const LinkButton = ({
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    ...rest
  }) => 
 (
    <button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event)
        history.push(to)
      }}
    />
  );

export default withRouter(LinkButton)