import React, { useEffect, useState } from 'react';
import fireAuth from './firebase';
import App from '../App/App';

import Loading from '../common/Loading';

export default function Auth(){
	const [authState, setAuthState] = useState({status: 'loading'});

	const refreshToken = async () => {
	
		// console.log("Refreshing token....Previous:", authState.idTokenResult.token );
		let idTokenResult = await authState.user.getIdTokenResult(true);
		setAuthState({ ...authState, idTokenResult } );
	
		// console.log("New token:", idTokenResult.token );
		return idTokenResult.token;
	}


useEffect(() => {
	return fireAuth.onAuthStateChanged( async user => {
		
		if(user){
		//   console.log('Already signed in--------------------');
		//   console.log('All of user object', user);
		//   console.log('Current user:', user.uid); 
		  let idTokenResult = await user.getIdTokenResult();
		  
		//   console.log('idTokenResult.token',idTokenResult.token);
		  
		  let hasuraClaims = idTokenResult.claims["https://hasura.io/jwt/claims"];
		//   console.log('Initial user claims:', idTokenResult.claims["https://hasura.io/jwt/claims"]);
	
		  if(!!!hasuraClaims){
			setAuthState({ status: 'fetching'}); 
			// console.log('No hasura claims. Entering refetch loop...');
			// console.log('Initial user claims:', idTokenResult.claims);
		  
			let numRetries = 0;
			var timeout =  setInterval( async ()=>{
	
			//   console.log('Inside setInterval. Retry: ', numRetries);
			  setAuthState({ status: 'fetching' });
			  numRetries++;
	
			  idTokenResult = await user.getIdTokenResult(true);
			  
			//   console.log('Retesting for hasura claims');
			  hasuraClaims = idTokenResult.claims["https://hasura.io/jwt/claims"];
			//   console.log('User claims after refresh:', hasuraClaims);
			  
			  if(hasuraClaims){
				setAuthState({ status: 'in', idTokenResult, user } );
				// console.log('Got Hasura claims!!')
				clearInterval(timeout);

			  }
	
			  if(numRetries > 10){
				// console.log('Waited for 10 seconds and no result. Aborting!\nAnd I\'m telling!!!!')
				clearInterval(timeout);
			  }
	
			}, 1000);
		  
		} else {

			setAuthState({ status: 'in', idTokenResult, user } );
		}
	
		
		let secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
		let epcochToExpire = idTokenResult.claims.exp;
		let renewInSecs = epcochToExpire - secondsSinceEpoch;
		// console.log("Next token refresh in:", renewInSecs);

		} else {
		  console.log('Not signed in.................');
		  fireAuth.signInAnonymously().catch(function(error){
			console.log('********** ERROR *************');
			console.log(error.code);
			console.log(error.message);
		  })
		}
		
	})
  }, [])



  if( authState.status === 'fetching' ){
//   return <div><p>Fetching Claims!!!!! {authState.retries}</p></div>
  return <div><Loading percentage={'Loading...75%'} /></div>
}

  if( authState.status === 'loading' ){
	//   return <div><p>Initializing.........................</p></div>
	return <div><Loading percentage={'Loading...50%'} /></div>
  } else {
		return <App authState={authState} setAuthState={setAuthState} refreshToken={refreshToken} />;
  };

}
