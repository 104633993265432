import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { getLang } from '../utils';
import { ADD_NEW_SPEAKER } from '../../graphql/Speakers';

function AddSpeaker({sessionId}) {

	const lang = getLang();

	const m = {
		plsTypeSpeakerName: {
			"en": "Please, type the speaker name.",
			"es": "Por favor, teclea el nombre del orador."
		},
		must30chars: {
			"en": "Must be 30 characters or less",
			"es": "Debe ser 30 caracteres o menos"
		},
		instructions: {
			"en": "Instructions:",
			"es": "Instrucciones:"
		},
		li1: {
			"en": "Call on the speaker/respondent.",
			"es": "Nombra en voz alta al orador."
		},
		li2: {
			"en": "Immediatly afterwards, type the name and click \"Add speaker\".",
			"es": "Después, captura su nombre y haz click en \"Agregar Orador\""
		},
		li3: {
			"en": "The name will appear automatically to all connected evaluators.",
			"es": "El nombre aparecerá automáticamente a todos los evaluadores conectados."
		},
		note: {
			"en": "Note: Remember to select the time score after each speech.",
			"es": "Nota: Recuerda capturar la calificación de tiempo después de cada discurso."
		},
		typeSpeakerName: {
			"en": "Type speaker name",
			"es": "Nombre del orador"
		},
		addSpeaker: {
			"en": "Add Speaker",
			"es": "Agregar Orador"
		}
	};

	const validate = values => {
		const errors = {};

	  
		if (!values.name) {
		  errors.name = m.plsTypeSpeakerName[lang];
		} else if (values.name.length > 30) {
		  errors.name = m.must30chars[lang];
		}
	  
		if (!values.table_topic_id) {
			errors.table_topic_id = 'Required';
		  } else if (values.table_topic_id.length > 50) {
			errors.table_topic_id = 'Must be 50 characters or less';
		  }
	  
		return errors;
	  };

	  const [addNewSpeaker] = useMutation(ADD_NEW_SPEAKER);

	return(
		<div className="w3-container w3-teal" >
		<h3>{m.instructions[lang]}</h3>
		<ol>
			<li>{m.li1[lang]}</li>
			<li>{m.li2[lang]}</li>
			<li>{m.li3[lang]}</li>
		</ol>
		<p>{m.note[lang]}</p>
		<Formik
			initialValues={{ name: '', table_topic_id: sessionId}}
			validate={validate}
			onSubmit={(values, {setSubmitting, resetForm}) => {
				// setTimeout(() => {
				// 	console.log('AddSpeaker:', values);
				// 	setSubmitting(false);
				// 	resetForm({});
				// }, 1400);
				addNewSpeaker({variables: values});
				setSubmitting(false);
				resetForm({});
			}}
		>
		{({ errors, touched, isSubmitting }) => (
			
			<Form>
				
				<Field className="w3-input w3-border" name="name" type="text"  placeholder={m.typeSpeakerName[lang]} />
				<ErrorMessage className="w3-yellow w3-text-orange" name="name" component="div" />
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<button style={{marginTop: '8px'}} className="w3-button w3-khaki" type="submit" disabled={isSubmitting}>{m.addSpeaker[lang]}</button>
				</div>
				<br/>
				
	
			</Form>
			
		)}
		</Formik>
		</div>
	)

}

export default AddSpeaker;