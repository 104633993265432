import gql from 'graphql-tag';


const tableTopicFragment =  gql`
  fragment tabletopic on table_topics {
	id
	created_by
	tt_date
	director
	short_desc
	id1
	id2
	id3
	status
  }
`;

export const GET_TABLE_TOPIC = gql`
	subscription tabletopic($id1: String!, $id2: String!, $id3: String!) {
	table_topics(where: {_and: {id1: {_eq: $id1}, id2: {_eq: $id2}, id3: {_eq: $id3}}}) {
		...tabletopic
	}
	}
	${tableTopicFragment}
`;

export const NEW_TABLE_TOPIC = gql`
	mutation newTableTopic($tt_date: timestamp!, $director: String!, $short_desc: String! ) {
	insert_table_topics(objects: {tt_date: $tt_date, director: $director, short_desc: $short_desc}) {
		affected_rows
		returning {
			...tabletopic
		}
	}
	}
	${tableTopicFragment}
`;

export const WATCH_TT_STATUS = gql`
subscription getTTStatus($table_topic_id: Int!) {
  table_topics(where: {id: {_eq: $table_topic_id}}) {
    status
  }
}
`;

//Variables
// {"table_topic_id": 4}



export const ENABLE_VOTES_SUBMISSION = gql`
mutation enableVotesSubmission($table_topic_id: Int!) {
  update_table_topics(_set: {status:"submitting"},where: {id: {_eq: $table_topic_id}}) {
    affected_rows
  }
}
`;

//Variables
// {"table_topic_id": 41}
