import React from 'react';
import {isMobile} from 'react-device-detect';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getLang } from '../utils';

const ShareButton = ({dataTT}) => {

	const lang = getLang();

	const m = {
		share: {
			"en": "Share",
			"es": "Compartir"
		},
		copy: {
			"en": "Copy",
			"es": "Copiar"
		}
	};

	const whatsAppRef = 'whatsapp://send?text='
	+ 'https://tabletopics.app/tt/' + dataTT.table_topics[0].id1 + dataTT.table_topics[0].id2 + dataTT.table_topics[0].id3
	+ '%0a' + dataTT.table_topics[0].short_desc;

	const linkText = 'https://tabletopics.app/tt/' + dataTT.table_topics[0].id1 + dataTT.table_topics[0].id2 + dataTT.table_topics[0].id3;

	const shareId = () => {

		if (navigator.share) {
			navigator.share({
			  title: 'TableTopics.app',
			  text: dataTT.table_topics[0].short_desc,
			  url: 'https://tabletopics.app/tt/' + dataTT.table_topics[0].id1 + dataTT.table_topics[0].id2 + dataTT.table_topics[0].id3,
			})
			  .then(() => console.log('Successful share'))
			  .catch((error) => console.log('Error sharing', error));
		  } else {
			  console.log('No web share api support')
		  }

	}

	return(
		<>
		{ navigator.share 
			? <button className="w3-teal w3-button w3-margin-left w3-round-large" onClick={shareId}>{m.share[lang]} <i className="fas fa-share-alt w3-margin-left w3-large w3-text-white"></i></button>

			: 
			isMobile ? 
				<a href={whatsAppRef} className="w3-button w3-teal w3-round-large w3-margin-left" data-action="share/whatsapp/share">
					<div style={{display: 'flex', alignItems: 'center'}}>
						<span>{m.share[lang]} </span>
						<i className="fab fa-whatsapp w3-margin-left w3-xlarge w3-text-white"></i>
					</div>
				</a>
				:
				<CopyToClipboard text={linkText}>
					<button className="w3-teal w3-button w3-margin-left w3-round-large">{m.copy[lang]} <i className="far fa-copy w3-margin-left w3-large w3-text-white"></i></button>
			  	</CopyToClipboard>
		}
		</>
	)
};

export default ShareButton;