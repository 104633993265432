import React from 'react';
import { Formik, Field, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { getLang } from '../utils';

const EnterSessionForm = ({authState, history}) => {

	const lang = getLang();

	const m = {
		typeSessionId: {
			"en": "Type the session Id",
			"es": "Escriba el Id de sesión"
		},
		id9chars: {
			"en": "Session Id must be 9 characters",
			"es": "El Id debe ser de 9 caracteres"
		},
		enterSessionId: {
			"en": "Enter session Id",
			"es": "Escriba el Id de sesión"
		},
		go: {
			"en": "Go!",
			"es": "¡Entrar!"
		},
		activating: {
			"en": "Activating...",
			"es": "Activando..."
		},
		egSessionId: {
			"en": "E.g. 344673098",
			"es": "Ej. 344673098"
		}
	};

	const validate = values => {
		const errors = {};

		if (!values.sessionId) {
		  errors.sessionId = m.typeSessionId[lang];
		} else if (values.sessionId.length !== 9) {
		  errors.sessionId = m.id9chars[lang];
		}
		return errors;
	  };


	return(
		<>
		<Formik
					initialValues={{ sessionId: ''}}
					validate={validate}
					onSubmit={(values, {setSubmitting}) => {
						history.push(`/tt/${values.sessionId}`)
						setSubmitting(false);
					}}
				>
				{({ isSubmitting, errors, touched }) => (
					<Form>
						<label>{m.enterSessionId[lang]}</label>
						<Field name="sessionId" type="text"  placeholder={m.egSessionId[lang]} maxLength="9" className="w3-input w3-border" />
						
						{ errors.sessionId && touched.sessionId ? <p className="w3-text-red">{errors.sessionId}</p> : null }
						
						{authState.status === 'in' ?
							<button type="submit" className="w3-button w3-teal w3-margin" disabled={isSubmitting}>{m.go[lang]}</button>
							:
							<button  className="w3-button w3-teal w3-margin" disabled={true}>m.activating[lang]</button>
						}
					</Form>
				)}
		</Formik>
		</>
	);
};

export default withRouter(EnterSessionForm);