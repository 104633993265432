import React from 'react';
import { Link  } from 'react-router-dom';
import { getLang } from '../utils';

const YourPlacesDisplay = ({winners, allSpeakers, title, message1, message2, message3, bgColor}) => {

	const lang = getLang();

	const m = {
		first: {
			"en": "First",
			"es": "Primero"
		},
		second: {
			"en": "Second",
			"es": "Segundo"
		},
		third: {
			"en": "Third",
			"es": "Tercero"
		},
		tie: {
			"en": " (tie)",
			"es": " (empate)"
		}
	}

	return(
		<>
		<div className={(bgColor ? bgColor : "w3-blue") + " w3-padding"}>
			<p>{title}</p>
			<span>{m.first[lang]}: { winners["first"].length>1 && m.tie[lang] }</span>
			{
				winners["first"].map( winnerId => <li key={winnerId}>{allSpeakers.find(speaker => speaker.id === Number(winnerId)).name } </li> )
			}
			<span>{m.second[lang]}: { winners["second"].length>1 && m.tie[lang] }</span>
			{
				winners["second"].map( winnerId => <li key={winnerId}>{allSpeakers.find(speaker => speaker.id === Number(winnerId)).name } </li> )
			}
			<span>{m.third[lang]}: { winners["third"].length>1 && m.tie[lang] }</span>
			{
				winners["third"].map( winnerId => <li key={winnerId}>{allSpeakers.find(speaker => speaker.id === Number(winnerId)).name } </li> )
			}

		</div>

		{ (message1 || message2 || message3) &&
			<div className={(bgColor ? bgColor : "w3-indigo") + " w3-padding w3-center"}>
				{message1 && <p>{message1}</p>}
				{message2 && <p>{message2}</p>}
				{message3 && <p>{message3} <Link to="/">https://tabletopics.app</Link></p>}
			</div>
		}
		</>
	);
}

export default YourPlacesDisplay;