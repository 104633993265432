import gql from 'graphql-tag';

export const INSERT_EVALUATOR_AND_SCORES = gql`
mutation insertEvaluatorScores($name: String!, $table_topic_id: Int!, $winners: String!, $tt_scores: tt_score_arr_rel_insert_input!) {
	insert_tt_evaluator(
	  objects: [
		{
		  name: $name,
		  winners: $winners
		  table_topic_id: $table_topic_id
		  tt_scores: $tt_scores
		}
	  ]
	) 
	{
		affected_rows
	}
}`;