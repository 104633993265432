import React, { useState } from 'react';

import { useSubscription } from '@apollo/react-hooks';
import { GET_TABLE_TOPIC } from '../../graphql/TableTopics';
import { Redirect } from 'react-router-dom';

import TableTopicInfo from '../common/TableTopicInfo';
import SpeakerListSubscription from '../common/SpeakerList';
import RegisterEvaluator from './RegisterEvaluator';
import Loading from '../common/Loading';
import NavBar from './NavBar';


function TableTopic({match, authState}){

	const [ evaluator, setEvaluator] = useState("");
	const ttid = match.params.ttid;
	
	const { loading: loadingTT, error: errorTT, data: dataTT } = useSubscription(
		GET_TABLE_TOPIC, {variables: {
			"id1": ttid.substring(0,3),
			"id2": ttid.substring(3,6),
			"id3": ttid.substring(6,9),
		  }});

	let isAdmin = false;
	let sessionStatus = "TBD";

	if(loadingTT){
		return <Loading percentage={`Fetching Id ${ttid.substring(0,3)}-${ttid.substring(3,6)}-${ttid.substring(6,9)}`} />
	}


	if(dataTT && dataTT.table_topics[0]) {
		isAdmin = dataTT.table_topics[0].created_by === authState.user.uid ? true : false;
		sessionStatus = dataTT.table_topics[0].status;
		// console.log('dataTT', dataTT);
		// console.log('userID', authState.user.uid);
		// console.log('isAdmin', isAdmin);
	
		if(isAdmin){
			return <Redirect to={`/tta/${ttid}`} />
		}

		return(
			<div>
				<NavBar />
				<TableTopicInfo  loadingTT={loadingTT} errorTT={errorTT} dataTT={dataTT} />
				<RegisterEvaluator evaluator={evaluator} setEvaluator={setEvaluator} />

				<br/>
		
				<div style={{display: evaluator ? 'block' : 'none' }}>
				<SpeakerListSubscription 
					isAdmin={isAdmin}
					sessionId={dataTT.table_topics[0].id}
					sessionStatus={sessionStatus}
					evaluator={evaluator}
				/>
				</div>
			</div>
		);
	} else {
		return <div><p>The session id {ttid} was not found</p></div>
	}
}

export default TableTopic;