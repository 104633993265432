import gql from 'graphql-tag';

export const GET_SPEAKERS = gql`
	query getSpeakers($table_topic_id: Int!) {
	tt_speaker(where: {table_topic_id: {_eq: $table_topic_id}}, order_by: {created_at: asc}) {
		id
		created_at
		name
		table_topic_id
		time_score
	}
}
`;

export const NOTIFY_NEW_SPEAKER = gql`
subscription getSpeakers($table_topic_id: Int!) {
	tt_speaker(where: {table_topic_id: {_eq: $table_topic_id}}, order_by: {created_at: asc}   ) {
		id
		created_at
		name
		table_topic_id
		time_score
	}
}
`;

// Variables
// {"table_topic_id": 23}


const ttSpeakerFragment =  gql`
  fragment ttSpeaker on tt_speaker {
	id
	name
	table_topic_id
	time_score
  }
`;

// export const GET_TABLE_TOPIC = gql`
// 	query tabletopic($id1: String!, $id2: String!, $id3: String!) {
// 	table_topics(where: {_and: {id1: {_eq: $id1}, id2: {_eq: $id2}, id3: {_eq: $id3}}}) {
// 		...tabletopic
// 	}
// 	}
// 	${tableTopicFragment}
// `;

export const ADD_NEW_SPEAKER = gql`
mutation newSpeaker($name: String!, $table_topic_id: Int!) {
  insert_tt_speaker(objects: {name: $name, table_topic_id: $table_topic_id}) {
    affected_rows
    returning {
      ...ttSpeaker
    }
  }
}
${ttSpeakerFragment}
`;

export const SET_TIME_SCORE = gql`
mutation updateTimeScore($id: Int!, $time_score: Int!) {
	update_tt_speaker(_set: {time_score: $time_score}, where: {id: {_eq: $id}}) {
	  affected_rows
	}
  }
`;