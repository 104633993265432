import React from 'react';
import { Link } from 'react-router-dom';

import { getLang } from '../utils';

const GoToDashbordPanel = ({sessionStatus, ttid}) => {

	const lang = getLang();

	const m= {
		text1: {
			"en": "If you like, you can submit your personal votes with the green button above.",
			"es": "Si lo deseas, puedes incluir tus votos usando el botón verde de arriba."
		},
		text2: {
			"en": "Proceed to dashboard to calculate the winners.",
			"es": "Ve al tablero de control para calcular los ganadores."
		},
		goToBtn: {
			"en": "Go to Dashboard",
			"es": "Ir a Tablero"
		}
	}

	return(
		<div style={{display: sessionStatus === "submitting" ? "block" : "none", marginBottom: "28px"}}
			className="w3-container w3-indigo w3-padding">
			<p>{m.text1[lang]}</p>
			<p>{m.text2[lang]}</p>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<Link  className="w3-button w3-aqua" to={`/rdashboard/${ttid}`} >{m.goToBtn[lang]}</Link>
			</div>
		</div>
	);
};

export default GoToDashbordPanel;