import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import NavBar from './NavBar';
import { getLang } from '../utils';

import { NEW_TABLE_TOPIC } from '../../graphql/TableTopics';
import { Redirect } from 'react-router-dom';

function NewSession(){

	const lang = getLang();

	const m = {
		required: {
			"en": "Required",
			"es": "Requerido"
		},
		is30chars: {
			"en": "Must be 30 characters or less",
			"es": "Máximo 30 caracteres"
		},
		is50chars: {
			"en": "Must be 50 characters or less",
			"es": "Máximo 50 caracteres"
		},
		sessionDate: {
			"en": "Session date",
			"es": "Fecha de la sesión"
		},
		topicsMaster: {
			"en": "Topicsmaster",
			"es": "Director de Temas"
		},
		comments: {
			"en": "Comments",
			"es": "Comentarios"
		},
		createSession: {
			"en": "Create Session",
			"es": "Crear Sesión"
		},
		yourName: {
			"en": "Your name",
			"es": "Su nombre"
		},
		commentPlaceholder: {
			"en": "E.g. Session 658, Metropolis Club",
			"es": "Ej. Sesión 658, Club Metrópolis"
		}
	}

	const validate = values => {
		const errors = {};
		if (!values.tt_date) {
		  errors.tt_date = m.required[lang];
		} 
	  
		if (!values.director) {
		  errors.director = m.required[lang];
		} else if (values.director.length > 30) {
		  errors.director = m.is30chars[lang];
		}
	  
		if (!values.short_desc) {
			errors.short_desc = m.required[lang];
		  } else if (values.short_desc.length > 50) {
			errors.short_desc = m.is50chars[lang];
		  }
	  
		return errors;
	  };



	  const [redirectState, setRedirectState] = useState({redirect:false, redirectTo: ""});

	  const setRedirect = (data) =>{
		const newTableTopic = data.insert_table_topics.returning[0];
		const newTableTopicId = newTableTopic.id1 + newTableTopic.id2 + newTableTopic.id3;
		setRedirectState({redirect:true, redirectTo: "/tta/"+newTableTopicId});
	  }

	  const [createTableTopic] = useMutation(NEW_TABLE_TOPIC, {onCompleted: setRedirect});


		
	if(redirectState.redirect){
		return <Redirect to={redirectState.redirectTo} />
	}


	return(
		<>
		<NavBar />
		<div className="w3-container" style={{padding: '20px 16px'}}>
		<Formik
			initialValues={{ tt_date: '', director: '', short_desc: '' }}
			validate={validate}
			onSubmit={(values) => {
				// setTimeout(() => {
				// 	console.log('Formik values:', values);
				// alert(JSON.stringify(values, null, 2));
				// setSubmitting(false);
				// }, 400);
				createTableTopic({variables: values});
				// setSubmitting(false);
			}}
    >
		{({ errors, touched, isSubmitting }) => (
	  <Form>
        <label htmlFor="tt_date">{m.sessionDate[lang]}</label>
        <Field className="w3-input w3-border" name="tt_date" type="date"  placeholder={m.sessionDate[lang]}  />
        <ErrorMessage className="w3-text-red" style={{marginBottom: '8px'}} name="tt_date" component="div" />
	  	<br/>
        <label htmlFor="director">{m.topicsMaster[lang]}</label>
        <Field className="w3-input w3-border" name="director" type="text"  placeholder={m.yourName[lang]} />
        <ErrorMessage className="w3-text-red" style={{marginBottom: '8px'}} name="director"  component="div"/>
		<br/>
        <label htmlFor="short_desc">{m.comments[lang]}</label>
        <Field className="w3-input w3-border" name="short_desc" type="text"  placeholder={m.commentPlaceholder[lang]} />
        <ErrorMessage className="w3-text-red" style={{marginBottom: '8px'}} name="short_desc"  component="div"/>
		<br/>

		<div style={{display: 'flex', justifyContent: 'center'}}>
			<button className="w3-button w3-teal w3-large " type="submit" disabled={isSubmitting}>{m.createSession[lang]}</button>
		</div>

      </Form>
	  )}
    </Formik>

		</div>
		</>
	);
}

export default NewSession;