import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { getLang } from '../utils';

const RegisterEvaluator = ({evaluator, setEvaluator}) => {

	const lang = getLang();

	const m = {
		instructions: {
			"en": "Instructions",
			"es": "Instrucciones"
		},
		instructions1: {
			"en": "Type your name and click \"Begin\".",
			"es": "Escriba su nombre y oprima \"Comenzar\""
		},
		instructions2: {
			"en": "Speakers/respondents will appear below.",
			"es": "Los oradores aparecerán abajo."
		},
		evaluatortag: {
			"en": "Evaluator",
			"es": "Evaluador"
		},
		typeyourname: {
			"en": "Type your name",
			"es": "Escribe tu nombre"
		 },
		 comenzarBtn: {
			 "en": "Begin",
			 "es": "Comenzar"
		 }
	}

	const validate = values => {
		const errors = {};

		if (!values.name) {
		  errors.name = 'You must type your name.';
		} else if (values.name.length > 30) {
		  errors.name = 'Must be 30 characters or less';
		}
		return errors;
	  };



		  return (
	  		<>
			<div className="w3-container w3-teal" >
				<h3>{m.instructions[lang]}:</h3>
				<ol>
					<li>{m.instructions1[lang]}</li>
					<li>{m.instructions2[lang]}</li>
				</ol>
		  		
				<h3 style={{display: evaluator ? 'block': 'none'  }}>{m.evaluatortag[lang]}: {evaluator}</h3>
				
				<div style={{display: evaluator ? 'none': 'block'  }}>
				<Formik
					initialValues={{ name: ''}}
					validate={validate}
					onSubmit={(values, {setSubmitting}) => {
						setEvaluator(values.name);
						setSubmitting(false);
					}}
				>
				{({ isSubmitting }) => (
					<Form>

						<p>{m.evaluatortag[lang]}:</p>
						<Field name="name" type="text"  placeholder={m.typeyourname[lang]} />
						<button style={{marginLeft: '8px'}} className="w3-button w3-khaki" type="submit" disabled={isSubmitting} >{m.comenzarBtn[lang]}</button>
						<br/>
						<ErrorMessage className="w3-yellow w3-text-orange w3-margin"  name="name" component="div"/>
						<br/>
					</Form>
				)}
				</Formik>
				</div>
			</div>
			</>
		)
};

export default RegisterEvaluator;