import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SET_TIME_SCORE } from '../../graphql/Speakers';
import { getLang } from '../utils';

const TimeScore = ({isAdmin, scores, speaker, isSubmitted}) => {

	const lang = getLang();

	const m = {
		time: {
			"en": "Time score",
			"es": "Tiempo"
		},
		touchNumber: {
			"en": "Touch/click a number to select it",
			"es": "Click/toca un número para seleccionarlo"
		},
		automaticallyDisplayed: {
			"en": "Captured by Topicsmaster",
			"es": "Lo captura el Topicsmaster"
		},
		pending: {
			"en": "Pending",
			"es": "Pendiente"
		}
	};

	const [timeOpen, setTimeOpen] = useState(false);
	const [saveTimeScore] = useMutation(SET_TIME_SCORE);

	const selected = {padding: '0px 8px', color: 'white', backgroundColor: '#009688'};
	const notSelected = {color: '#009688', padding: '8px'};
	const timeScore = scores[speaker.id] && scores[speaker.id].time;

	const toggleOpen = () => {
		setTimeOpen(!timeOpen);
	}

	const scoringSystem = () => {

		if(lang==="es"){

			return(
				<>
		
				<span className="w3-green" onClick={toggleOpen} style={{display: timeOpen ? "none" : "inline"}}>Más info...</span>
		
				<span className="w3-green" onClick={toggleOpen} style={{display: timeOpen ? "inline" : "none"}}>Menos info...</span>
		
				<div style={{display: timeOpen ? "block" : "none"}}  className="notranslate">
					<p>Cada club/consurso decidirá las calificaciones para el tiempo de los oradores. </p>
					<p>Te recomendamos:</p>

					<table className="w3-table w3-striped w3-centered">
						<thead>
							<tr>
								<th>Tiempo</th><th>Calificación</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1:10 o menos</td><td>0</td>
							</tr>
							<tr>
								<td>1:11 a 1:20</td><td>1</td>
							</tr>
							<tr>
								<td>1:21 a 1:30</td><td>2</td>
							</tr>
							<tr>
								<td>1:31 a 1:40</td><td>3</td>
							</tr>
							<tr>
								<td>1:41 a 1:50</td><td>4</td>
							</tr>
							<tr>
								<td>1:51 a 2:05</td><td>5</td>
							</tr>
							<tr>
								<td>2:06 o más</td><td>0</td>
							</tr>
						</tbody>
					</table>
				</div>
				</>
				)

		} else {

			return(
		<>

		<span className="w3-green" onClick={toggleOpen} style={{display: timeOpen ? "none" : "inline"}}>More info...</span>

		<span className="w3-green" onClick={toggleOpen} style={{display: timeOpen ? "inline" : "none"}}>Less info...</span>

		<div style={{display: timeOpen ? "block" : "none"}} className="notranslate">
			
			<p>Each club/contest will decide the scoring system for the time used by each speaker.</p>
			<p>Here is a recommendation:</p>
			<table className="w3-table w3-striped w3-centered">
				<thead>
					<tr>
						<th>Time used</th><th>Score</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1:10 or less</td><td>0</td>
					</tr>
					<tr>
						<td>1:11 to 1:20</td><td>1</td>
					</tr>
					<tr>
						<td>1:21 to 1:30</td><td>2</td>
					</tr>
					<tr>
						<td>1:31 to 1:40</td><td>3</td>
					</tr>
					<tr>
						<td>1:41 to 1:50</td><td>4</td>
					</tr>
					<tr>
						<td>1:51 to 2:05</td><td>5</td>
					</tr>
					<tr>
						<td>2:06 or more</td><td>0</td>
					</tr>
				</tbody>
			</table>
		</div>
		</>
		)}
	};

	function setTimeScore(newScore){
		if(timeScore !== newScore && !isSubmitted){
			saveTimeScore({variables: { "id": speaker.id, "time_score": newScore}})
		}
	}

	return(
	<>
		{ isAdmin ?
				<>
					<div style={{display: 'flex'}}>
					<div style={{flex:1 }} className="w3-text-brown">
						<span className="w3-large">{m.time[lang]}</span>
						<br/><span>{m.touchNumber[lang]}</span>
					</div>


					<div className="w3-right notranslate" style={{display: 'flex', alignItems: 'center'}}>
						<span className="w3-large" onClick={setTimeScore.bind(null,0)} style={timeScore === 0 ? selected : notSelected}>0</span>
						<span className="w3-large" onClick={setTimeScore.bind(null,1)} style={timeScore === 1 ? selected : notSelected}>1</span>
						<span className="w3-large" onClick={setTimeScore.bind(null,2)} style={timeScore === 2 ? selected : notSelected}>2</span>
						<span className="w3-large" onClick={setTimeScore.bind(null,3)} style={timeScore === 3 ? selected : notSelected}>3</span>
						<span className="w3-large" onClick={setTimeScore.bind(null,4)} style={timeScore === 4 ? selected : notSelected}>4</span>
						<span className="w3-large" onClick={setTimeScore.bind(null,5)} style={timeScore === 5 ? selected : notSelected}>5</span>
					</div>
					</div>
					{scoringSystem()}
				</>
			:
				<>
				<div style={{display: 'flex'}}>
					<div style={{flex:1 }} className="w3-text-brown">
					<span className="w3-large">{m.time[lang]}</span>
						<br/><span>{m.automaticallyDisplayed[lang]}</span>
					</div>

					<div style={{display: 'flex', alignItems: 'center', alignContent: 'center', paddingRight: '12px'}} className="w3-right notranslate">
						<button style={{opacity: '0', cursor: 'auto'}} className="tt-score-button" disabled={true}>-</button>
							<span style={{padding: '0px 12px'}} className="w3-text-teal w3-xlarge" >{scores[speaker.id] && (scores[speaker.id].time !== null ? scores[speaker.id].time : m.pending[lang]) }</span>
						<button style={{opacity: '0', cursor: 'auto'}} className="tt-score-button" disabled={true}>+</button>
					</div>
				</div>
				{scoringSystem()}
				</>
		}
	</>
	);
}

export default TimeScore;