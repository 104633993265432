import gql from 'graphql-tag';

export const NOTIFY_NEW_EVALUATOR = gql`
subscription getEvaluators($table_topic_id: Int!) {
	tt_evaluator(where: {table_topic_id: {_eq: $table_topic_id}}, order_by: {created_at: asc}) {
	  id
	  created_by
	  created_at
	  name
	  winners
	}
  }
  `;

  // Variables
// {"table_topic_id": 23}

export const UPDATE_TABLE_TOPIC = gql`
mutation updateTableTopics($table_topic_id: Int!, $status: String!, $winners: String!) {
  update_table_topics(_set: {status:$status, winners:$winners},where: {id: {_eq: $table_topic_id}}) {
    affected_rows
  }
}
`;

//Variables
// {"table_topic_id": 23,
// "status": "closed",
//   "winners": "ganadores"}
