import React from 'react';
import './Loading.css';

const Loading = ({percentage}) => 
	(	<div style={{paddingTop: '220px',display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
			<div style={{flex: 1}} className="lds-hourglass"></div>
			<h3 style={{flex: 1}} className="w3-text-teal">{percentage ? percentage: "33%"}</h3>
		</div>
	);

export default Loading;