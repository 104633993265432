import React, { useState, useEffect } from "react";
import { useSubscription, useMutation } from "@apollo/react-hooks";
import { INSERT_EVALUATOR_AND_SCORES } from '../../graphql/Evaluators';

import SpeakersDisplay from '../common/SpeakersDisplay';
import YourPlacesDisplay from '../common/YourPlacesDisplay';
import { sumScores, getMax, getLang } from '../utils';

import { NOTIFY_NEW_SPEAKER } from '../../graphql/Speakers';
// import { WATCH_TT_STATUS } from '../../graphql/TableTopics';

// function containsObject(obj, list) {
//     var i;
//     for (i = 0; i < list.length; i++) {
//         if (list[i] === obj) {
//             return true;
//         }
//     }

//     return false;
// }

const SpeakerList = ({ttid, sessionId, sessionStatus, allSpeakers, isAdmin, evaluator}) => {

	const [scores, setScores] = useState({});
	const [initialRun, setInitialRun] = useState(true);
	const [missingMessage, setMissingMessage] =  useState("");
	const [winners, setWinners] = useState("");
	const [saveEvaluatorAndScores] = useMutation(INSERT_EVALUATOR_AND_SCORES);

	const lang = getLang();

	const m = {
		yourPlacesTitle: {
			"en": "Places according to your scores",
			"es": "Lugares de acuerdo a sus calificaciones"
		},
		waitUntilFinished: {
			"en": "After the last speaker has finished you will be able to submit your votes.",
			"es": "Cuando termine el último orador podrás enviar tus resultados."
		},
		calculateSubmitBtn: {
			"en": "Calculate places & Submit",
			"es": "Calcular y Enviar"
		},
		resultsSubmitted: {
			"en": "Your votes have been submitted.",
			"es": "Sus resultados se han enviado."
		},
		waitForTopicsmaster: {
			"en": "Please, wait for the Topicsmaster to announce the winners.",
			"es": "Por favor, espere a que el Director de Temas anuncie a los ganadores."
		},
		nextStep: {
			"en": "Next step",
			"es": "Siguiente paso"
		},
		goDashboardMessage: {
			"en": " go to the dashboard to see all casted votes and calculate winners.",
			"es": " ve al tablero de control para ver los votos y calcular los ganadores."
		}, goDashboardButton: {
			"en": "Go to Dashboard",
			"es": "Ir a Tablero de Control"
		},
		scrollToDashboard: {
			"en": "Next step: proceed to the dashboard.",
			"es": "Siguiente paso: ir al tablero de control. "
		},
		whenYouTopics: {
			"en": "REMEMBER: When you are the Topicsmaster, use this app at ",
			"es": "RECUERDA: Cuando seas el  Topicsmaster, usa esta app en "
		}
	};

	useEffect(()=>{
		 const iniScores = allSpeakers.reduce( (acc, speaker) => {
			return { ...acc, [speaker.id]: {form: 0, deve: 0, topi: 0, time: speaker.time_score } };
		}, scores);
		setScores(prevScores => {
			return iniScores;
		});
		setInitialRun(false);
	}
	,[]);

	useEffect( () => {
		if(!initialRun){
			const allScores = allSpeakers.reduce( (acc, speaker) => {
				if(acc[speaker.id]){
					return { ...acc,
						[speaker.id]: {...scores[speaker.id], time: speaker.time_score } 
					};
				} else {
					return { ...acc, [speaker.id]: {form: 0, deve: 0, topi: 0, time: speaker.time_score } };
				}
			}, scores);
			setScores(prevScores => {
				return allScores;
			});
		}
	}, [allSpeakers]);


	const calcPlaces = () => {

		let totals = {};

		const missingTime = Object.keys(scores).filter(spkr => scores[spkr].time === null );

		// console.log('missingTime', missingTime);
		// console.log('allSpeakers', allSpeakers);

		if(missingTime.length) {
			setMissingMessage(
				<div className="w3-container w3-deep-orange">
					<p>The following speakers have time score pending:</p>
					<ul>
						{/* { missingTime.map(spkrId =>  <li key={spkrId}>{allSpeakers.filter(spkr => spkr.id == spkrId )[0].name}</li>) } */}
						{ missingTime.map(spkrId =>  <li key={spkrId}>{allSpeakers.find(spkr => spkr.id === Number(spkrId) ).name}</li>) }
					</ul>
					<p>Please inform the Topicsmaster, and submit again.</p>
				</div>
			);
			return;
		}

		setMissingMessage("");
		Object.keys(scores).map(spkr => totals = {...totals, [spkr]: sumScores(scores[spkr])});

		const first = getMax(totals);
		// console.log('first', first);
		first.map(winner => delete totals[winner]);

		const second =  getMax(totals);
		// console.log('second', second);
		second.map(winner => delete totals[winner]);

		const third = getMax(totals);
		// console.log('third', third);
		third.map(winner => delete totals[winner]);

		const localWinners = {"first": first, "second": second, "third": third};
		setWinners(localWinners);

		// console.log('winners', JSON.stringify(localWinners));
		// console.log('totals', totals);
		
		let finalScores = allSpeakers.reduce( (acc,spkr) => {

			return([
				...acc,
				{
				table_topic_id: sessionId,
				speaker_id: spkr.id,
				name: spkr.name,
				form_score: scores[spkr.id].form,
				development_score: scores[spkr.id].deve,
				theme_score: scores[spkr.id].topi,
				time_score: scores[spkr.id].time,
				total_score: sumScores(scores[spkr.id])
			}] )
		}, []);

		saveEvaluatorAndScores({
			variables: { 
				"name": evaluator,
				"table_topic_id": sessionId,
				"winners": JSON.stringify(localWinners),
				"tt_scores": { "data": finalScores }
			}
		});

		// console.log('sessionId', sessionId);
		// console.log('finalScores', JSON.stringify(finalScores));

	}


	const handleIncrement = (event) => {

		// console.log('event', event);
		// console.log('event.target', event.target);
		// console.log('event.target.id', event.target.id);
		
		const key = (event.target.id).substring(3,7);
		const sid = (event.target.id).substring(7);

		setScores(prevState => {
			let newVal = prevState[sid][key] + 1;
			newVal = newVal>5 ? 5: newVal;

			return { ...prevState, [sid]: {...prevState[sid], [key]: newVal} }
		});
	}

	const handleDecrement = (event) => {
		
		const key = (event.target.id).substring(3,7);
		const sid = (event.target.id).substring(7);

		setScores(prevState => {
			let newVal = prevState[sid][key] - 1;
			newVal = newVal<0 ? 0: newVal;

			return { ...prevState, [sid]: {...prevState[sid], [key]: newVal} }
		});

		// const key = (event.target.id).substring(3);

		// setState(prevState => {
		// 	const newVal = prevState[key] ? prevState[key]-1 : 0;
		// 	return {...prevState, [key]: newVal }
		// })
	}
	
		return(
			<>
				<SpeakersDisplay 
					allSpeakers={allSpeakers}
					scores={scores}
					handleIncrement={handleIncrement}
					handleDecrement={handleDecrement}
					isAdmin={isAdmin}
					isSubmitted={!!winners}
				/>
				{ winners && <YourPlacesDisplay 
					title={m.yourPlacesTitle[lang]}
					winners={winners}
					allSpeakers={allSpeakers}
					message1={m.resultsSubmitted[lang]}
					message2={ isAdmin ? m.scrollToDashboard[lang] : m.waitForTopicsmaster[lang]}
					message3={ !isAdmin && m.whenYouTopics[lang]}
				/>
				}
				{ missingMessage }
				{ !winners 
					?  
					<>
					{ (sessionStatus === "open" && !isAdmin) &&
						<div style={{display: 'flex', justifyContent: 'center', padding: '48px 12px 30px 12px'}}>
							<div className="w3-container w3-amber">{m.waitUntilFinished[lang]}</div>
						</div>
						
					}
					{ 
						sessionStatus === "submitting" ? <div style={{display: 'flex', justifyContent: 'center', padding: '12px 12px 40px 12px'}}>
							<button style={{marginTop: '8px'}} className="w3-button w3-teal"  onClick={calcPlaces} >
								{m.calculateSubmitBtn[lang]}
							</button>
						</div> 
						: null
					}
					</>
					:
					null
				}
			</>
		)
}


const SpeakerListSubscription = ({ttid, sessionId, isAdmin, evaluator, sessionStatus}) => {

	const { loading: loadingSpeakers, error: errorSpeakers, data: dataSpeakers } = useSubscription(NOTIFY_NEW_SPEAKER, {variables: {"table_topic_id": sessionId}});
	// const { loading: loadingStatus, error: errorStatus, data: dataStatus } = useSubscription(WATCH_TT_STATUS, {variables: {"table_topic_id": sessionId}});


	if (loadingSpeakers) {
	  return <span>Fetching speakers...</span>;
	}
	if (errorSpeakers) {
		// console.log('Subscription errorSpeakers:', errorSpeakers);
		// console.log('Subscription errorStatus:', errorStatus);
	  return <span>Subscription Error</span>;
	}

	return (
	  <SpeakerList 
		isAdmin={isAdmin}
	  	allSpeakers={dataSpeakers.tt_speaker} 
		  sessionId={sessionId}	
		  sessionStatus={sessionStatus} 
		  ttid={ttid}
		  evaluator={evaluator} 
	  />
	);
  };

export default SpeakerListSubscription;