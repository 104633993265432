export const sumScores = (speakerScores) => {
	return speakerScores.form + speakerScores.deve + speakerScores.topi + ( speakerScores.time ? speakerScores.time : 0 );
};

export const getMax = object => {
	return Object.keys(object).filter(x => {
		 return object[x] == Math.max.apply(null, 
		 Object.values(object));
   });
};

export const getLang = () => {

	const browserLang = navigator.language;

	return browserLang.substr(0,2) === 'es' ? 'es' : 'en';

}