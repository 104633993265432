import React from 'react';
import { getLang } from '../utils';

const Instructions = () => {

	const lang = getLang();

	if(lang === 'es'){
		return(
			<div className="w3-container w3-teal w3-padding">
				<p>Lo que debes saber:</p>
				<ol>
					<li>Topicsmaster envía el id de sesión por WhatsApp.</li>
					<li>Cada evaluador entra a la sesión y anota su nombre.</li>
					<li>Cada nuevo orador aparecerá automáticamente cuando el Topicsmaster lo agregue.</li>
					<li>Cada evaluador registra sus calificaciones, que al final serán enviadas al Topicsmaster.</li>
				</ol>
		</div>
		);
	} else {

		return(
			<div className="w3-container w3-teal w3-padding">
				<p>What you need to know:</p>
				<ol>
					<li>Topicsmaster sends id session via WhatsApp.</li>
					<li>Every evaluator/viewer enters the session and writes his/her name.</li>
					<li>Every speaker name will appear automatically when the Topicsmaster adds them.</li>
					<li>Every evaluator/viewer registers their scores, which will be sent to the Topicsmaster at the end.</li>
				</ol>
		</div>
		);
	}
};

export default Instructions;