import React from 'react';
import TimeScore from './TimeScore';
import { sumScores, getLang } from '../utils';

const SpeakersDisplay = (
	{allSpeakers, 
		scores, 
		handleIncrement, 
		handleDecrement, 
		isAdmin,
		isSubmitted
	}) => {


	const lang = getLang();

	const m = {
		speakers: {
			"en": "Speakers",
			"es": "Oradores"
		},
		formTitle :{
			"en": "",
			"es": "Forma"
		}, 
		formSubtitle: {
			"en": "Posture, Body Language, Voice, Eye contact",
			"es": "Postura, ademanes, vista, voz"
		},
		deveTitle: {
			"en": "Development",
			"es": "Desarrollo"
		},
		deveSubtitle: {
			"en": "Organization, opening, body, close",
			"es": "Estructura, apertura, cuerpo, cierre"
		},
		topicTitle: {
			"en": "On topic",
			"es": "Tema"
		},
		topicSubtitle: {
			"en": "",
			"es": "Apego, efectividad al tema asignado"
		},
		noSpeakersYet: {
			"en": "No speakers yet.",
			"es": "Aún no hay oradores."
		}
	};

	return(
		<>
		<h3 className="w3-text-brown">{m.speakers[lang]}:</h3>
		<ol>
		{ allSpeakers.length 
			? (
				
				allSpeakers.map( (speaker) => {
					
					return(
					<li className="w3-text-brown" style={{paddingBottom: '20px'}} key={speaker.id}>
						<div className="w3-bar-item w3-green w3-padding-small notranslate">
						<span className="w3-large">{speaker.name}</span>  
						<span style={{backgroundColor: '#1a711d'}} className="w3-padding-small w3-right">TOTAL: { scores[speaker.id] &&  sumScores(scores[speaker.id]) }</span>
						</div>
							
						<div style={{backgroundColor: '#edf3f0'}} className="w3-container w3-padding-small w3-border">
							<div style={{display: 'flex'}}>
							<div style={{flex:1 }} className="w3-text-brown">
								<span className="w3-large">{m.formTitle[lang]}</span>
								<br/>{m.formSubtitle[lang]}
							</div>
							
							<div style={{display: 'flex', alignItems: 'center', alignContent: 'center', paddingRight: '12px'}} className="w3-right notranslate">
								<button className="tt-score-button" id={`decform${speaker.id}`} onClick={handleDecrement} disabled={isSubmitted}>-</button>
									<span className="w3-xlarge w3-text-teal" style={{padding: '0px 12px'}}>{ scores[speaker.id] && scores[speaker.id].form }</span>
								<button className="tt-score-button" id={`incform${speaker.id}`} onClick={handleIncrement} disabled={isSubmitted}>+</button>
							</div>
							</div>
						</div>

						<div style={{backgroundColor: '#edf3f0'}} className="w3-bar-item w3-padding-small w3-border">
							<div style={{display: 'flex'}}>
							<div style={{flex:1 }} className="w3-text-brown">
								<span className="w3-large">{m.deveTitle[lang]}</span>
								<br/>{m.deveSubtitle[lang]}
							</div>
							
							<div style={{display: 'flex', alignItems: 'center', alignContent: 'center', paddingRight: '12px'}} className="w3-right notranslate">
								<button className="tt-score-button" id={`decdeve${speaker.id}`} onClick={handleDecrement} disabled={isSubmitted}>-</button>
									<span className="w3-xlarge w3-text-teal" style={{padding: '0px 12px'}}>{scores[speaker.id] && scores[speaker.id].deve }</span>
								<button className="tt-score-button" id={`incdeve${speaker.id}`} onClick={handleIncrement} disabled={isSubmitted}>+</button>
							</div>
							</div>
						</div>

						<div style={{backgroundColor: '#edf3f0'}} className="w3-bar-item w3-padding-small w3-border">
							<div style={{display: 'flex'}}>
							<div style={{flex:1 }} className="w3-text-brown">
								<span className="w3-large">{m.topicTitle[lang]}</span>
								<br/>{m.topicSubtitle[lang]}
							</div>
							<div style={{display: 'flex', alignItems: 'center', alignContent: 'center', paddingRight: '12px'}} className="w3-right notranslate">
								<button className="tt-score-button" id={`dectopi${speaker.id}`} onClick={handleDecrement} disabled={isSubmitted}>-</button>
									<span className="w3-xlarge w3-text-teal" style={{padding: '0px 12px'}}>{scores[speaker.id] && scores[speaker.id].topi }</span>
								<button className="tt-score-button" id={`inctopi${speaker.id}`} onClick={handleIncrement} disabled={isSubmitted}>+</button>
								</div>
								</div>
						</div>

						<div style={{backgroundColor: '#edf3f0'}} className="w3-bar-item w3-padding-small w3-border">
						<TimeScore 
							scores={scores} 
							speaker={speaker} 
							isAdmin={isAdmin}
							isSubmitted={isSubmitted} />
						</div>
					</li>)})
				
			)
			: <div>{m.noSpeakersYet[lang]}</div>
		}
		</ol>
		</>
	)
}

export default SpeakersDisplay;