import React, {useState} from 'react';

import YourPlacesDisplay from '../common/YourPlacesDisplay';

const Evaluator = ({evaluator, allSpeakers}) => {

	const [open, setOpen] = useState(false);

	return(
		<>
			<div style={{marginTop: '10px'}}>
				<button onClick={() => setOpen(!open)} className="w3-button w3-block w3-left-align w3-indigo">
					{evaluator.name} <span> </span>
					{ open ? <i style={{marginLeft: '5 px'}} className="fa fa-caret-up"></i> : <i className="fa fa-caret-down"></i> }
				</button>


				<div id={evaluator.id} className={open ? "w3-container w3-show w3-cyan": "w3-container w3-hide w3-cyan"}>
					<YourPlacesDisplay winners={JSON.parse(evaluator.winners)} allSpeakers={allSpeakers} />
				</div>
			</div>
		</>
	);

}

export default Evaluator;