import React from 'react';

import LinkButton from '../common/LinkButton';
import NavBar from './NavBar';
import EnterSessionForm from './EnterSessionForm';
import PurposeMsg from './PurposeMsg';
import Instructions from './Instructions';

import { getLang }  from '../utils';

function Home({authState}){

	const lang = getLang();

	const m = {
		hero1: {
			"en": "The app for your sessions...",
			"es": "La app para tus sesiones..."
		},
		hero2: {
			"en": "online and on-site.",
			"es": "en línea y presenciales"
		},
		createNewSessionBtn: {
			"en": "Create new session",
			"es": "Crear nueva sesión"
		},
		activating: {
			"en": "Activating...",
			"es": "Activando..."
		},
		or: {
			"en": "or",
			"es": "o"
		}
	}

	return(
		<>
		<NavBar />
		<div>

		<div className="w3-display-container w3-content w3-center" 
			style={{maxWidth:'768px'}}>
		<img className="w3-image" src="/images/homehero.jpg" alt="Me" width="1500" height="400" />
		<div className="w3-display-topmiddle w3-padding-large w3-wide w3-text-light-grey w3-center" style={{width: 'fit-content'}}>
			<div className="w3-container w3-black w3-opacity" >
				{/* <h1 className="w3-hide-small w3-hide-medium w3-xxxlarge">Welcome to TableTopics.app</h1>
				<h3 className="w3-xxlarge w3-hide-large">Welcome to TableTopics.app</h3> */}
			<p className="w3-large w3-white">{m.hero1[lang]}</p>
			<p className="w3-large w3-white">{m.hero2[lang]}</p>
			</div>
		</div>
		</div>

		<PurposeMsg />

		<Instructions />

		<div className="w3-container w3-center w3-padding w3-margin">
				{authState.status === 'in' ?
					<LinkButton  className="w3-button w3-teal"  to="/newsession" >{m.createNewSessionBtn[lang]}</LinkButton>
					:
					<LinkButton  className="w3-button w3-teal" disabled={true}>{m.activating[lang]}</LinkButton>
				}
				
				<p>{m.or[lang]}</p>

				<EnterSessionForm authState={authState} />

			
		</div>
			
		</div>
		</>
	);
}

export default Home;