import React from 'react';
import './App.css';
import 'w3-css/w3.css';
import '../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '../../../node_modules/@fortawesome/fontawesome-free/css/solid.min.css'
import '../../../node_modules/@fortawesome/fontawesome-free/css/brands.min.css'
import '../../../node_modules/@fortawesome/fontawesome-free/css/regular.min.css'

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from '../Home/Home';
import TableTopic from '../TableTopic/TableTopic';
import TableTopicAdmin from '../TableTopicAdmin/TableTopicAdmin';
import NewSession from '../NewSession/NewSession';
import ResultsDashboard from '../ResultsDashboard/ResultsDashboard';

import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { split, ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ApolloProvider } from '@apollo/react-hooks';



function App({authState, refreshToken}) {

const isIn = authState.status === 'in'
// console.log('App authState.token:', authState.idTokenResult.token);
const headers = isIn ? { Authorization: `Bearer ${authState.idTokenResult.token}` } : {}



const httpLink = new HttpLink({
  // uri: 'http://localhost:8080/v1/graphql',
  uri: 'https://learninghasura.herokuapp.com/v1/graphql',
  headers,
});

// const wsLink = new WebSocketLink({
//   // uri: 'ws://localhost:8080/v1/graphql',
//   uri: 'wss://learninghasura.herokuapp.com/v1/graphql',
//   options: {
//     reconnect: true,
//     connectionParams: {
//       headers,
//     },
//   },
// });

const wsLink = new WebSocketLink({
  uri: 'wss://learninghasura.herokuapp.com/v1/graphql',
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      
      let secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
      let epcochToExpire = authState.idTokenResult.claims.exp;
      
      let token = authState.idTokenResult.token;

      if ( epcochToExpire <= secondsSinceEpoch) {
        // console.log("Calling refreshToken()",  (new Date()));
        token = await refreshToken();
      }

      // console.log("returning connectionParams,",  (new Date()), " token: ", token);
      return {
        headers: {
          
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    },
  },
})

const splitLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
);

// const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let err of graphQLErrors) {
//       switch (err.extensions.code) {
//         case 'UNAUTHENTICATED':
//           // error code is set to UNAUTHENTICATED
//           // when AuthenticationError thrown in resolver
//           console.log('UNAUTHENTICATED ERROR. ', (new Date()) );
//           // modify the operation context with a new token
//           const oldHeaders = operation.getContext().headers;
//           operation.setContext({
//             headers: {
//               ...oldHeaders,
//               authorization: refreshToken(),
//             },
//           });
//           // retry the request, returning the new observable
//           return forward(operation);

//           default:
//             return;
//       }
//     }
//   }
//   if (networkError) {
//     console.log('[Network error]:', (new Date()) );
//     console.log('[Network error]:', networkError);
//     // if you would also like to retry automatically on
//     // network errors, we recommend that you use
//     // apollo-link-retry
//   }
// }
// );


const client = new ApolloClient({
  // link: ApolloLink.from([ new RetryLink(), onErrorLink, splitLink ]),
  link: splitLink,
  cache: new InMemoryCache(),
});

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <>
          <div></div>
          <div className="App">
          <Route path="/" exact render={(props) => <Home {...props} authState={authState} />} />
          <Route path="/tt/:ttid" render={(props) => <TableTopic {...props} authState={authState} />} />
          <Route path="/tta/:ttid" render={(props) => <TableTopicAdmin {...props} authState={authState} />} />
          <Route path="/newsession" component={NewSession} />
          <Route path="/rdashboard/:ttid" render={(props) => <ResultsDashboard {...props} authState={authState} /> } />
          </div>
          
          </>
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
