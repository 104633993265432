import React from 'react';

import { useSubscription } from '@apollo/react-hooks';
import { GET_TABLE_TOPIC } from '../../graphql/TableTopics';
import { Redirect } from 'react-router-dom';

import { getLang } from '../utils';
import TableTopicInfo from '../common/TableTopicInfo';
import EvaluatorsListSubscription from './EvaluatorsList';
import NavBar from './NavBar';

const ResultsDashboard =  ({match, authState}) => {

	const lang = getLang();

	const m = {
		warningText1: {
			"en": "Evaluators and their casted votes will appear below.",
			"es": "Los evaluadores y sus votos aparecerán abajo."
		},
		warningText2: {
			"en": "Please, allow a reasonable amount of time for everybody to submit their ballots.",
			"es": "Por favor, otorga un tiempo razonable para que todos envíen sus votos."
		},
	}

	const ttid = match.params.ttid;

	const { loading: loadingTT, error: errorTT, data: dataTT } = useSubscription(
		GET_TABLE_TOPIC, {variables: {
			"id1": ttid.substring(0,3),
			"id2": ttid.substring(3,6),
			"id3": ttid.substring(6,9),
		  }});

	let isAdmin = false;

	if(loadingTT){
		return <div><p>Fetching info for session {ttid}</p></div>
	};

	if(dataTT && dataTT.table_topics[0]) {
		isAdmin = dataTT.table_topics[0].created_by === authState.user.uid ? true : false;

		if(!isAdmin){
			return <Redirect to={`/tt/${ttid}`} />
		}

		return(
			<div>
				<NavBar />
				<TableTopicInfo  loadingTT={loadingTT} errorTT={errorTT} dataTT={dataTT} />
				<br/>
				<div className="w3-container w3-amber" >
					<p>{m.warningText1[lang]}</p>
					<p>{m.warningText2[lang]}</p>
				</div>


				<EvaluatorsListSubscription
					sessionId={dataTT.table_topics[0].id}
				/>

				{/* <br/>
				{isAdmin &&  <SpeakerListSubscription 
					isAdmin={isAdmin}
					sessionId={dataTT.table_topics[0].id}
					evaluator={`${dataTT.table_topics[0].director} (Topicsmaster)`}
				/>} */}
				
			</div>
		);
	} else {
		return <div><p>The session id {ttid} was not found.</p></div>
	}

};

export default ResultsDashboard;