import React from 'react';

import { useSubscription } from '@apollo/react-hooks';
import { GET_TABLE_TOPIC } from '../../graphql/TableTopics';
import { Redirect } from 'react-router-dom';

import TableTopicInfo from '../common/TableTopicInfo';
import EnableSubmissionPanel from './EnableSubmissionPanel';
import GoToDashbordPanel from './GoToDashbordPanel';
import AddSpeaker from './AddSpeaker';
import SpeakerListSubscription from '../common/SpeakerList';
import NavBar from './NavBar';


function TableTopicAdmin({match, authState}){

	const ttid = match.params.ttid;
	
	const { loading: loadingTT, error: errorTT, data: dataTT } = useSubscription(
		GET_TABLE_TOPIC, {variables: {
			"id1": ttid.substring(0,3),
			"id2": ttid.substring(3,6),
			"id3": ttid.substring(6,9),
		  }});

	let isAdmin = false;
	let sessionStatus = "TBD";

	if(loadingTT){
		return <div><p>Fetching info for session {ttid}</p></div>
	};

	if(dataTT && dataTT.table_topics[0]) {
		isAdmin = dataTT.table_topics[0].created_by === authState.user.uid ? true : false;
		sessionStatus = dataTT.table_topics[0].status;
		// console.log('dataTT', dataTT);
		// console.log('userID', authState.user.uid);
		// console.log('isAdmin', isAdmin);
	
		if(!isAdmin){
			return <Redirect to={`/tt/${ttid}`} />
		}

		return(
			<div>
				<NavBar />
				<TableTopicInfo  loadingTT={loadingTT} errorTT={errorTT} dataTT={dataTT} />
				<br/>
				<AddSpeaker sessionId={dataTT.table_topics[0].id} /> 
				
				<br/>
				<SpeakerListSubscription 
					isAdmin={isAdmin}
					sessionStatus={sessionStatus}
					sessionId={dataTT.table_topics[0].id}
					ttid={ttid}
					evaluator={`${dataTT.table_topics[0].director} (Topicsmaster)`}
				/>

				<EnableSubmissionPanel 
					sessionStatus={sessionStatus} 
					sessionId={dataTT.table_topics[0].id}
				/>

				<GoToDashbordPanel
					sessionStatus={sessionStatus} 
					ttid={ttid}
				/>
				
			</div>
		);
	} else {
		return <div><p>The session id {ttid} was not found</p></div>
	}
}

export default TableTopicAdmin;